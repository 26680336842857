import { create } from "zustand";
import { PersistOptions, persist } from "zustand/middleware";
import { produce } from "immer";
import CryptoJS from 'crypto-js';
import { UserState, User, initUser } from "./initialState";

export const useUserStore = create<UserState>() (
  persist<UserState>(
    (set, get) => ({
      userInfo: CryptoJS.AES.encrypt(JSON.stringify(initUser), process.env.NEXT_PUBLIC_HASH_CODE!).toString(),
      updateUser: (userInfo: User) => {
        set(
          produce((draft: UserState) => {
            const encryptedUser = CryptoJS.AES.encrypt(JSON.stringify(userInfo), process.env.NEXT_PUBLIC_HASH_CODE!).toString();
            draft.userInfo = encryptedUser;
          })
        )
      },
      getUser: () => {
        const state = get();

        if (state.userInfo.constructor === ({}).constructor) {
          set(
            produce((draft: UserState) => {
              const encryptedUser = CryptoJS.AES.encrypt(JSON.stringify(state.userInfo), process.env.NEXT_PUBLIC_HASH_CODE!).toString();
              draft.userInfo = encryptedUser;
            })
          )

          return state.userInfo;
        } else if (state.userInfo.constructor === "text".constructor) {
          try {
            return JSON.parse(CryptoJS.AES.decrypt(state.userInfo, process.env.NEXT_PUBLIC_HASH_CODE!).toString(CryptoJS.enc.Utf8));
          } catch (error) {
            return initUser;
          }
        } else {
          return initUser;
        }
      }
    }), {
      name: "user",
    } as PersistOptions<UserState>
  ) 
)
