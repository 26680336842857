import { create } from "zustand";
import { PersistOptions, persist } from "zustand/middleware";
import { produce } from "immer";
import CryptoJS from 'crypto-js';
import { TopBanner, TopBannerState, initTopBanner } from "./initialState";

export const useTopBannerStore = create<TopBannerState>() (
  persist<TopBannerState>(
    (set, get) => ({
      topBanner: CryptoJS.AES.encrypt(JSON.stringify(initTopBanner), process.env.NEXT_PUBLIC_HASH_CODE!).toString(),
      updateTopBanner: (topBanner: TopBanner) => {
        set(
          produce((draft: TopBannerState) => {
            const encryptedUser = CryptoJS.AES.encrypt(JSON.stringify(topBanner), process.env.NEXT_PUBLIC_HASH_CODE!).toString();
            draft.topBanner = encryptedUser;
          })
        )
      },
      getTopBanner: () => {
        const state = get();

        if (state.topBanner.constructor === ({}).constructor) {
          set(
            produce((draft: TopBannerState) => {
              const encryptedUser = CryptoJS.AES.encrypt(JSON.stringify(state.topBanner), process.env.NEXT_PUBLIC_HASH_CODE!).toString();
              draft.topBanner = encryptedUser;
            })
          )

          return state.topBanner;
        } else if (state.topBanner.constructor === "text".constructor) {
          try {
            return JSON.parse(CryptoJS.AES.decrypt(state.topBanner, process.env.NEXT_PUBLIC_HASH_CODE!).toString(CryptoJS.enc.Utf8));
          } catch (error) {
            return initTopBanner;
          }
        } else {
          return initTopBanner;
        }
      }
    }), {
      name: "topBanner"
    } as PersistOptions<TopBannerState>
  )
)
